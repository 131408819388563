import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import withRedux from 'next-redux-wrapper';
import { DefaultSeo, NextSeo } from 'next-seo';
import Router, { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
//import "../public/resources/odometer.css";
import legacyTheme from '../src/app/theme';
import AnalyticsHeader, { FacebookScriptAnalytics } from '../src/components/analytics/AnalyticsHeader';
import ErrorBoundary from '../src/components/common/ErrorBoundary/ErrorBoundary';
import Notifier from '../src/components/common/Notifier';
import { ReferralProvider } from '../src/components/contexts/ReferralProvider';
import { makeStore } from '../src/redux/store';
import { ACTIONS, Analytics } from '../src/settings/analytics';
import { appWithTranslation } from 'next-i18next';
import SEO from '../src/settings/seo';
import { saveClientIpCookie } from '../src/shared/cookie-handler';
import { useEffect } from 'react';
import CustomSnackbar from '../src/components/common/CustomerSnackbar';
import browserLogger from '../src/settings/browser-logger';
import SnackBar from '../src/remitbee/components/snackbar/SnackBar';
import Head from 'next/head';
import { useMediaQuery } from '@material-ui/core';
import theme from '../src/remitbee/theme/Theme';
import useGTM from '../src/components/analytics/useGTM';
import { brazeSessionStart } from '../src/redux/customer/actions';
import { initializeBraze } from '../src/settings/braze/braze-service';

// Trigger page view event for analytics
// isProductionEnv &&
Router.events.on('routeChangeComplete', (url) => Analytics.track(ACTIONS.PAGE_VIEW, { url }));

const RemitbeeCp = (props) => {
  /* * @todo remove this method to enable the Automatic static optimization. The current limitation is the i18n library that
   * recommends the namespacesRequired props to be passed down on this getInitialProps method.  */

  const router = useRouter();
  useGTM();
  const [clusterAlert, setClusterAlert] = useState(['mts-aks-dev-2', 'mts-aks-prod-2'].includes(process.env.K8S_NAME))
  const hideCrispFromPages = ['/ratesV2', '/rates', '/rewards', '/rewardsV2', '/login', '/dashboard', '/signup', '/choose-services', '/inbox', '/escalation/issue-type', '/settings', '/business-account/dashboard', '/escalation/add-details', '/verification', '/e-sim']
  const crispFromPagesPosition = ['/balance-deposit', '/balance-withdrawal', '/recipients', '/exchange-currency', '/mobile-top-up', '/international-bill', '/gift-card']
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const isRecaptchaNeeded =
    router?.pathname?.includes('login') ||
    router?.pathname?.includes('signup') ||
    router?.pathname?.includes('escalation');

  const { Component, pageProps, store } = props;

  useEffect(() => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') return;
    const isBrazeInitialized = sessionStorage.getItem('braze_initialized');
    const { is_braze_enabled } = store?.getState()?.customer;

    const initializeBrazeCheck = async () => {
      const { success, data } = await brazeSessionStart();
      if (!success) return;
      data?.isBrazeEnabled && initializeBraze();
      sessionStorage.setItem('braze_initialized', 'true');
    };
    if (!(isBrazeInitialized && is_braze_enabled)) initializeBrazeCheck();
  }, []);


  useEffect(() => {
    const loadData = async () => {
      await saveClientIpCookie();
      try {
        /* visitorTracking(); */
      } catch (err) {
        if (browserLogger) browserLogger.error(err & err.message);
      }
    }

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    loadData();


    // const GTAG_ID = process.env.REACT_APP_ENV === 'prod' ? 'GTM-WP9Q2P' : 'GTM-T7CDXL2';
    // const timer = setTimeout(() => gtagScript(GTAG_ID), 0);

    // return () => {
    //   clearTimeout(timer);
    // };
  }, []);

  const hideCrisp = (path) => {
    const crispDisplay = (hideCrispFromPages.some(page => new RegExp(page).test(path)) && typeof document !== 'undefined') ? 'none' : 'block';
    const crispDisplayPostion = (crispFromPagesPosition.some(page => new RegExp(page).test(path)) && typeof document !== 'undefined') ? true : false;
    const styles = `
      .crisp-client .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge {
        display: ${crispDisplay} !important;
      }
      .crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
        display: ${crispDisplay} !important;
      }
      .crisp-client .cc-tlyw .cc-kxkl .cc-nsge[data-is-failure=false] {
        display: ${crispDisplay} !important;
      }
      .crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf{
        display: ${crispDisplay} !important;
      }
      .crisp-client .cc-1brb6[data-full-view=true] .cc-1yy0g .cc-1m2mf{
        display: ${crispDisplay} !important;
        bottom: ${crispDisplayPostion && isMobile ? 88 : 24}px!important;
        right: 16px!important;
      }
    `;

    const styleTag = document.createElement('style');
    styleTag.appendChild(document.createTextNode(styles));
    document.head.appendChild(styleTag);

    return () => {
      document.head.lastChild.remove();
    };
  }

  useEffect(() => {
    if (router?.asPath) hideCrisp(router?.asPath);
  }, [router?.asPath, isMobile]);

  // let metaNoReferer = null;
  let isLoggedIn = false;
  // Material UI classes prefix
  // const generateClassName = createGenerateClassName(
  //   {
  //     productionPrefix: 'rb-',
  //   }
  // );

  let initialState = null

  if (router && router.query && router.query.referrer) {
    initialState = router.query.referrer
  }
  // if (
  //   router &&
  //   router.route &&
  //   blackListReferer.includes(router.route)
  // ) {
  //   metaNoReferer = <meta name="referrer" content="no-referrer" />;
  //   // analyticsHeader = null;
  // }
  let seo = { ...SEO };
  switch (router && router.pathname) {
    case '/signup':
      seo = {
        title: "Sign Up | Remitbee Online Money Transfer",
        description: "Join Remitbee to send money from Canada to 40+ countries across the globe. Sign up today and get your free account to transfer money without fees!",
        canonical: `${router && router.query && router.query.lang === 'si' || router.query.lang === 'ta' || router.query.lang === 'es' ? `https://www.remitbee.com/${router.query.lang}/signup` : 'https://www.remitbee.com/signup'}`
      }
      break;
    case '/login':
      seo = {
        title: "Log In | Remitbee Online Money Transfer",
        description: "Welcome back to Remitbee! Please sign in to your personal account to send money online from Canada.",
        canonical: `${router && router.query && router.query.lang === 'si' || router.query.lang === 'ta' || router.query.lang === 'es' ? `https://www.remitbee.com/${router.query.lang}/login` : 'https://www.remitbee.com/login'}`
      }
      break;
    case '/dashboard':
    case '/send-money':
    case '/send-money/[step]':
    case '/exchange-currency':
    case '/wallet':
    case '/recipients':
    case '/add-recipients':
    case '/edit-recipients':
    case '/rewards':
    case '/rates':
    case '/balance':
      isLoggedIn = true;
      break;
    default:
      seo = { ...SEO }
  }

  const isProductionEnv = process.env.REACT_APP_ENV === 'prod';

  const getPageContent = () => {
    return (
      <ThemeProvider theme={legacyTheme}>
        <DefaultSeo {...seo} />
        {isLoggedIn ?
          <NextSeo
            additionalMetaTags={[{
              name: "robots",
              content: "noindex"
            }, {
              name: "googlebot",
              content: "noindex,nofollow"
            }]}
          />
          : null}
        <CssBaseline />
        <ErrorBoundary>
          <ReferralProvider initialState={initialState}>
            <Component {...pageProps} />
          </ReferralProvider>
        </ErrorBoundary>
        <Notifier />
        <AnalyticsHeader />
      </ThemeProvider>
    )
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      </Head>
      {/* IMPORTANT

        Facebook scripts are loaded next, don't move it to inside <head> tag
      */}
      <FacebookScriptAnalytics />
      {!isProductionEnv && clusterAlert && <CustomSnackbar position={{
        vertical: 'top',
        horizontal: 'center',
      }} message="Running on Cluster-2. Overseas and RabbitMQ operations blocked." handleClose={() => setClusterAlert(!clusterAlert)} />}
      <Provider store={store}>
        {isRecaptchaNeeded ?
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
            scriptProps={{
              defer: true,
              appendTo: 'head'
            }}
          >
            {getPageContent()}
          </GoogleReCaptchaProvider>
          : <>
            {getPageContent()}
          </>}
      </Provider>
      <SnackBar />
    </>
  );
}

/*
  DO NOT instance one getInitialProps function on this file.

  Next.js will disable static optimization logic and force all pages to be SSR.
  Also, it may break the next-i18next props injection.

  PS: getServerSideProps and getStaticProps not supported for _app.js.
*/

const RemitbeeCpWithTranslation = appWithTranslation(RemitbeeCp);
const RemitbeeCpWithRedux = withRedux(makeStore)(RemitbeeCpWithTranslation);
export default RemitbeeCpWithRedux;
